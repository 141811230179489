<template>
  <label class="checkbox-container">
    <input type="checkbox" :checked="checked" @change="update">
    <span class="checkbox-checkmark"></span>
    <span class="checkbox-text" v-if="text && text.length > 0">{{ text }}</span>
  </label>
</template>

<script>
export default {
  name: "ACheckbox",
  props: ['value', 'text'],
  data() {
    return {
      checked: this.value
    }
  },
  watch: {
    value(){
      this.checked = this.value;
    }
  },
  methods: {
    update() {
      this.checked = !this.checked;
      this.$emit('input', this.checked);
    }
  }
}
</script>

<style scoped>

</style>
